import texture1 from "./texture-1.svg";
import texture2 from "./texture-2.svg";
import texture3 from "./texture-3.svg";
import texture4 from "./texture-4.svg";
import texture5 from "./texture-5.svg";
import texture6 from "./texture-6.svg";

//
import texture1copy from "./texture-1-copy.svg";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  texture1,
  texture2,
  texture3,
  texture4,
  texture5,
  texture6,
  texture1copy,
};
