import React from "react";
import { useLightboxState } from "@context";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import Portal from "@hoc/Portal";

import LinkWithIcon from "@components/LinkWithIcon";
import ReactPlayer from "react-player";

export default function Lightbox() {
  const {
    lightboxOpen,
    setLightboxOpen,
    activeLightboxImage,
    setActiveLightboxImage,
    lightboxImages,
    setLightboxImages,
    lightboxVideo,
    setLightboxVideo,
  } = useLightboxState();

  const handleLightboxClose = () => {
    setLightboxOpen(false);

    setTimeout(() => {
      setLightboxImages([]); // empty context
      setLightboxVideo(false); // empty context
    }, 250);
  };

  return (
    <Portal
      {...{
        className: `${lightboxOpen ? "block" : "hidden"}`,
      }}
    >
      <div
        className={`grid grid-cols-14 w-full h-full p-10 pb-20 xl:p-20 fixed top-0 left-0 text-center text-white transition-all ${
          lightboxOpen
            ? "opacity-100 visible pointer-events-auto"
            : "opacity-0 invisible pointer-events-none"
        } z-[100]`}
      >
        <div className="absolute top-4 right-4 p-3 bg-black cursor-pointer">
          <LinkWithIcon
            icon="cross"
            color="mi-light"
            className="font-extrabold w-6 xl:w-8 h-6 xl:h-6 hover:scale-105 transition-transform"
            clickHandler={() => handleLightboxClose()}
          />
        </div>

        {lightboxImages.length > 1 && (
          <div
            className="hidden lg:flex items-center justify-center cursor-pointer"
            onClick={() =>
              setActiveLightboxImage(Math.max(0, activeLightboxImage - 1))
            }
          >
            <div className="w-12 xl:w-16 h-12 xl:h-16 rounded-full bg-black flex items-center justify-center relative cursor-pointer group">
              <AiOutlineLeft className="text-white w-6 xl:w-8 h-6 xl:h-6" />
              <span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
            </div>
          </div>
        )}
        <div className="col-span-full lg:col-span-12 lg:col-start-2 lightbox__inner flex items-center justify-center mx-auto w-full h-full top-0 left-0 relative">
          {lightboxImages.length > 0 && (
            <>
              {lightboxImages.map(({ full }, i) => {
                const image = getImage(full);
                const bg = convertToBgImage(image);

                return (
                  <div
                    key={i}
                    className={`lightbox__image w-full h-full absolute top-0 left-0 transition-all duration-500 ${
                      i === activeLightboxImage
                        ? "opacity-100 visible pointer-events-auto"
                        : "opacity-0 invisible pointer-events-none"
                    }`}
                  >
                    <BackgroundImage
                      {...bg}
                      className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-contain bg-center bg-no-repeat"
                      style={{ position: null }}
                    />
                  </div>
                );
              })}
            </>
          )}

          {lightboxVideo ? (
            lightboxVideo.mp4Url ? (
              <video
                width="100%"
                height="100%"
                controls
                poster={lightboxVideo.thumbnailUrl}
              >
                <source src={lightboxVideo.mp4Url} type="video/mp4" />
              </video>
            ) : (
              <div className="w-full aspect-video max-w-[1280px] mx-auto">
                <ReactPlayer
                  url={lightboxVideo.url}
                  width="100%"
                  height="100%"
                  className="aspect-video"
                  controls={true}
                />
              </div>
              //   <div className="w-full relative">
              //     <iframe
              //       type="text/html"
              //       src={lightboxVideo.url}
              //       allowfullscreen="allowfullscreen"
              //       className="w-full h-full absolute top-0 left-0"
              //     />
              //   </div>
            )
          ) : null}
        </div>

        {lightboxImages.length > 1 && (
          <div
            className="hidden lg:flex items-center justify-center cursor-pointer"
            onClick={() =>
              setActiveLightboxImage(
                Math.min(lightboxImages.length - 1, activeLightboxImage + 1)
              )
            }
          >
            <div className="w-12 xl:w-16 h-12 xl:h-16 rounded-full bg-black flex items-center justify-center relative cursor-pointer group">
              <AiOutlineRight className="text-white w-6 xl:w-8 h-6 xl:h-6" />
              <span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
            </div>
          </div>
        )}
      </div>
    </Portal>
  );
}
