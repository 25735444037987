import React from "react";
import PropTypes from "prop-types";
import Svg from "react-inlinesvg";
import cx from "classnames";

import svgs from "./svgs";

export default function Divider({
  name,
  src = null,
  className,
  fill = "fill-black",
  bg,
  fillOpacity = "100%",
}) {
  return (
    (name || src) && (
      <Svg
        src={src || svgs[name]}
        {...{
          className: cx(`z-50 ${bg}`, className, {
            "fill-white": fill === "fill-white",
            "fill-light": fill === "fill-light",
            "fill-lightgrey": fill === "fill-lightgrey",
            "fill-offwhite": fill === "fill-offwhite",
            "fill-primary": fill === "fill-blue",
            "fill-black": fill === "fill-black" || fill === "",
            "fill-grey": fill === "fill-grey",
            "fill-transparent": fill === "fill-transparent",
          }),
        }}
      />
    )
  );
}

Divider.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string,
};

Divider.defaultProps = {
  src: null,
};
